<template>
  <v-container grid-list-md>
    <v-row>
      <v-col v-if="isSubmitting" cols="12" md="10" offset-md="1" lg="8" offset-lg="2" id="formContainer">
        <v-card>
          <v-card-title>Submitting form...</v-card-title>
          <v-card-text>This should take only a few seconds.</v-card-text>
        </v-card>
      </v-col>
      <v-col v-else-if="submitted" cols="12" md="10" offset-md="1" lg="8" offset-lg="2" id="formContainer">
        <v-card>
          <v-toolbar>
            <v-toolbar-title>{{ name }} - Submitted</v-toolbar-title>
          </v-toolbar>
          <v-card-text v-html="confText"></v-card-text>
          <v-card-actions v-if="submitAgain">
            <v-btn text @click="resetForm">Reset Form to submit again</v-btn>
          </v-card-actions>
          <v-card-text v-if="paymentTotal > 0 && paymentReceipt">
            <p>Below is the receipt for your payment.</p>
            <v-row>
              <v-col cols="6">
                <span>Item</span>
              </v-col>
              <v-col cols="2">
                <span>Quantity</span>
              </v-col>
              <v-col cols="2">
                <span>Price/ea</span>
              </v-col>
              <v-col cols="2">
                <span>Item Total</span>
              </v-col>
            </v-row>
            <v-row v-for="({ name, quantity, unit_amount: { value } }, index) of paymentItems" :key="'line-item-' + index">
              <v-col cols="6">{{ name }}</v-col>
              <v-col cols="2">{{ quantity }}</v-col>
              <v-col cols="2">${{ value }}</v-col>
              <v-col cols="2">${{ quantity * value }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <span style="font-weight:bold">Total</span>
              </v-col>
              <v-col cols="2">
                <span style="font-weight:bold">${{ paymentTotal }}</span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col v-else cols="12" md="10" offset-md="1" lg="8" offset-lg="2" id="formContainer">
        <v-card v-if="infoCard">
          <v-card-title v-if="showTitle">{{ name }}</v-card-title>
          <v-card-text v-html="infoCardText"></v-card-text>
        </v-card>
        <v-toolbar v-else-if="showTitle" style="margin-bottom:1em">
          <v-toolbar-title>{{ name }}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text v-if="asteriskOnRequired" style="padding-top:8px;padding-bottom:8px">All required fields are marked with <span class="error--text fas fa-asterisk" style="font-size:.6em;transform:translateY(-.6em);margin-left:.2em;"></span></v-card-text>
        <template v-if="sectionType === 'card'">
          <section-card :sections="sections" :formData="formData" :submit-label="paymentTotal > 0 ? 'Pay $' + paymentTotal + ' and Submit' : 'Submit'" @update="update" @save="save" @submit="submit"></section-card>
        </template>
        <template v-else-if="sectionType === 'stepper'">
          <section-stepper :sections="sections" :formData="formData" :submit-label="paymentTotal > 0 ? 'Pay $' + paymentTotal + ' and Submit' : 'Submit'" @update="update" @save="save" @submit="submit"></section-stepper>
        </template>
        <template v-else-if="sectionType === 'stepper-v'">
          <section-stepper-vertical :sections="sections" :formData="formData" :submit-label="paymentTotal > 0 ? 'Pay $' + paymentTotal + ' and Submit' : 'Submit'" @update="update" @save="save" @submit="submit"></section-stepper-vertical>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>
<style>
div#formContainer div.v-card {
  margin: 1em 0;
}
div#formContainer div.row > div {
  padding-bottom: 0 !important;
}
</style>
<script>
import { ref, computed, watch, reactive } from '@vue/composition-api'
// import { uploadFile } from '@/helpers/functions'
import { getFormData } from '@/components/forms/functions.js'

export default {
  components: {
    SectionCard: () => import('@/components/forms/sections/Card'),
    SectionStepper: () => import('@/components/forms/sections/Stepper'),
    SectionStepperVertical: () => import('@/components/forms/sections/StepperVertical')
  },
  setup (props, { root }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const roles = computed(() => root.$store.state.roles)
    const formParam = computed(() => root.$route.params['form'])
    const formId = ref(null)
    const revisionId = computed(() => root.$route.params['revision'])
    const loadedRevision = ref('')
    const name = ref('')
    const showTitle = ref(true)
    const infoCard = ref(false)
    const infoCardText = ref('')
    const asteriskOnRequired = computed(() => root.$store.state.forms.formSettings.asteriskOnRequired)
    const sectionType = ref('card')
    const sections = ref([])
    const previousSubmission = ref(null)
    const isSubmitting = ref(false)
    const submitted = ref(false)
    const confText = ref('')
    const submitAgain = ref(false)

    const paymentActive = ref(false)
    const paymentRules = ref([])
    const paymentTotal = computed(() => root.$store.state.forms.payment.total)
    const paymentItems = computed(() => root.$store.state.forms.payment.items)
    const paymentReceipt = ref(false)

    watch(formParam, async () => {
      // Load the form
      if (formParam.value !== '') {
        const idRegex = /^[0-9a-f]{24}$/
        if (idRegex.test(formParam.value)) {
          formId.value = formParam.value
        } else {
          const { data } = await root.$feathers.service('forms/base').find({ query: { shortName: formParam.value, status: 'Active' } })
          if (data.length >= 1) {
            formId.value = data[0]._id
          } else {
            root.$store.dispatch('main/snackbar', { active: true, color: 'error', timeout: 6000, text: 'No active form was found matching the given name. Please try selecting a form from the list.' })
            root.$router.replace('/forms')
            return
          }
        }
        const { name: baseName, shortName, again, access: baseAccess, settings: baseSettings, payment, revisions } = await root.$feathers.service('forms/base').get(formId.value)
        name.value = baseName
        document.title = baseName
        submitAgain.value = again || false
        if (baseAccess) {
          for (let l in access.value) {
            if (l in baseAccess) access.value[l] = baseAccess[l]
          }
          if ('loginRequired' in baseAccess) {
            root.$store.commit('forms/setRequiresLogin', { loginRequired: baseAccess.loginRequired, form: shortName })
            if (root.$store.state.loggedIn && baseAccess.loginRequired) {
              if ('roles' in baseAccess && baseAccess.roles.length > 0) {
                let hasRole = false
                baseAccess.roles.forEach((role) => { if (role in roles.value) hasRole = true })
                if (!hasRole) {
                  root.$store.dispatch('main/snackbar', { active: true, color: 'error', timeout: 6000, text: 'You do not have permission to access the requested form.' })
                  root.$router.replace('/forms')
                }
              }
              if ('updatePrevious' in baseAccess && baseAccess.updatePrevious) {
                // Find the last submitted entry for this user (if there is one) and populate the form data with that last submission
                root.$feathers.service('forms/submission').find({ query: { 'form.base': formId.value, user: user.value._id, $limit: 1, $sort: { submittedDate: -1 } } }).then(({ data }) => {
                  if (data.length > 0 && 'data' in data[0]) {
                    previousSubmission.value = data[0]._id
                    for (const field in data[0].data) {
                      formData[field] = data[0].data[field]
                    }
                  }
                })
              }
            }
          }
        }
        if (baseSettings) {
          const settings = { id: formId.value }
          for (const { name, value } of baseSettings) {
            settings[name] = value
          }
          root.$store.commit('forms/setFormSettings', settings)
        }
        if (payment && typeof (payment) === 'object' && 'active' in payment && payment.active === true) {
          paymentActive.value = true
          const { department, foapal, receipt, rules } = payment
          paymentReceipt.value = receipt
          paymentRules.value = rules || []
          root.$store.commit('forms/updatePayment', { department, foapal })
        }
        let revId = revisions.active
        if (revisionId.value && idRegex.test(revisionId.value)) {
          revId = revisionId.value
        }
        if (revId != null) {
          const revData = await root.$feathers.service('forms/revision').get(revId)
          loadedRevision.value = revData._id
          showTitle.value = revData.showTitle
          infoCard.value = revData.infoCard
          infoCardText.value = revData.infoCardText
          sectionType.value = revData.sectionType
          sections.value = revData.sections
          confText.value = revData.confText
        } else {
          root.$store.dispatch('main/snackbar', { active: true, color: 'error', timeout: 6000, text: 'No active form was found matching the given name. Please try selecting a form from the list.' })
          root.$router.replace('/forms')
        }
      }
      if (root.$store.state.loggedIn) {
        // Check to see if there are any fields for First Name, Last Name, or Banner ID; if so, populate them with the current user's details
        for (const { inputs } of sections.value) {
          for (const { label, input } of inputs) {
            if (label === 'First Name') formData['First Name'] = user.value.preferred
            else if (label === 'Last Name') formData['Last Name'] = user.value.last
            else if (label === 'Banner ID') formData['Banner ID'] = user.value.bannerId
            else if (input === 'file') formData[label] = null
          }
        }
      }
    })

    const access = ref({
      loginRequired: false,
      roles: [],
      anonymous: false,
      updatePrevious: false
    })

    const formData = reactive({})

    function update ({ field, value, index, action }) {
      root.$store.commit('updateActivity')
      if (action === 'remove' && index != null) {
        formData[field].splice(index, 1)
      } else if (index != null) {
        if (!(field in formData)) formData[field] = []
        while (!(index in formData[field])) formData[field].push({})
        formData[field].splice(index, 1, value)
      } else {
        formData[field] = value
      }
      if (paymentActive.value) {
        // Run through the payment rules every time a change is made to update the total
        let total = 0
        const items = []
        for (const { name, field, amount, cond } of paymentRules.value) {
          let include = true
          for (const { field, value } of cond) {
            // The only possibilites for the field type are number, checkbox/switch, or a dropdown
            // ToDo: Add fields within repeatable blocks to the conditions
            if (field in formData) {
              let val = formData[field]
              if (Array.isArray(val)) {
                // This would be a multi-select dropdown (eventually may also include repeatable blocks)
                if (Array.isArray(value)) {
                  if (formData[field].filter(val => value.includes(val)).length === 0) include = false
                }
              } else if (Array.isArray(value)) {
                // Either a number field or a single-select dropdown
                if (!isNaN(parseInt(val)) && value.length === 2) {
                  val = parseInt(val)
                  if (value[0] !== '' && val < value[0]) include = false
                  if (value[1] !== '' && val > value[1]) include = false
                } else {
                  if (!value.includes(val)) {
                    include = false
                  }
                }
              } else {
                // A checkbox/switch (or possibly something else)
                if (val !== value) include = false
              }
            } else {
              include = false
            }
          }
          if (!include) continue
          if (field && field in formData) {
            let val = formData[field]
            if (Array.isArray(val)) {
              total += amount * val.length
              items.push({ name, quantity: val.length, unit_amount: { value: amount } })
            } else if (!isNaN(parseInt(val))) {
              val = parseInt(val)
              if (amount === 0) {
                total += val
                items.push({ name, quantity: 1, unit_amount: { currency_code: 'USD', value: val } })
              } else {
                total += amount * val
                items.push({ name, quantity: val, unit_amount: { currency_code: 'USD', value: amount } })
              }
            }
          } else if (field == null) {
            // Not based on a field but is a fixed amount
            total += amount
            items.push({ name, quantity: 1, unit_amount: { currency_code: 'USD', value: amount } })
          }
        }
        root.$store.commit('forms/updatePayment', { total, items })
      }
      dataArrTest.value = getFormData(sections.value, formData)
    }
    const submissionId = computed({
      get: () => root.$store.state.forms.submissionId,
      set: (id) => root.$store.commit('forms/setSubmissionId', id)
    })
    const dataArrTest = ref([])
    // Saves the current form data. This is triggered by the file uploads so that it always has a form submission to link the files to.
    async function save () {
      root.$store.commit('updateActivity')
      const dataArr = getFormData(sections.value, formData)
      if (submissionId.value === '') {
        const obj = {
          form: {
            name: name.value,
            base: formId.value,
            revision: loadedRevision.value
          },
          status: 'Unsubmitted',
          dataArr
        }
        if (user.value && typeof (user.value) === 'object' && !access.anonymous) {
          if ('_id' in user.value) obj.user = user.value._id
          if ('name' in user.value) obj.name = user.value.name
          if (access.updatePrevious && previousSubmission.value != null) {
            obj.previousSubmission = previousSubmission.value
          }
        }

        const data = await root.$feathers.service('forms/submission').create(obj)
        submissionId.value = data._id
      } else {
        await root.$feathers.service('forms/submission').patch(submissionId.value, { dataArr })
      }
    }
    async function submit (args) {
      isSubmitting.value = true
      await save()
      const patch = {
        status: 'Submitted',
        submittedDate: new Date()
      }
      if (args && 'payment' in args) patch.payment = args.payment
      root.$feathers.service('forms/submission').patch(submissionId.value, patch).then(({ data }) => {
        submitted.value = true
        isSubmitting.value = false
        // Clear the submission ID
        submissionId.value = ''
      })
    }

    function resetForm () {
      for (const field in formData) {
        delete formData[field]
      }
      isSubmitting.value = false
      submitted.value = false
    }

    return {
      user,
      roles,
      formId,
      revisionId,
      name,
      showTitle,
      infoCard,
      infoCardText,
      asteriskOnRequired,
      sectionType,
      sections,
      previousSubmission,
      isSubmitting,
      submitted,
      confText,
      submitAgain,
      paymentActive,
      paymentRules,
      paymentTotal,
      paymentItems,
      access,
      formData,
      update,
      submissionId,
      save,
      submit,
      resetForm,
      dataArrTest
    }
  }
}
</script>
